<template>
    <a :class="`btn std center white relative ${btnClass}`" v-bind:href="link">
      <span class="relative z4">{{name}}</span>
    </a>
</template>

<script>


import { EventBus } from "@/event-bus.js";

export default {
     name: 'SubmitButton',
    props: {
      name: String,
      event_name: String,
      button_style: Boolean,
      btnAnimation: Boolean,
      link: String,
      btnClass: String

    },
    data() {
      return {
        debug: false,
        details: false,
        style_class: '',
      }
    },
    created() {
      if(this.style != "") {
        this.style_class = this.style;
      }
     }, 

     methods: {
      submit: function() {
          EventBus.$emit(""+this.event_name);
      },
      log: function (obj) {
        if(this.debug) {
          console.log(obj)
        }
      }
    }
}
</script>

<style scoped>
  .ghostbtn{
    color: darkred;
  }
  .graybtn:hover{
    background-color: red;
  }

  @media(max-width: 500px){
    .graybtn{
      white-space: normal;
    }
  }
  .graybtn::after{
    content: '';
    display: block;
    transition: 2s ease;
    min-width: 0px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #000;
    opacity: .3;
  }
  .inactive{
    pointer-events: none;
    background-color: #a3a3a3;
  }
  .btnanimation::after{
    min-width: 100%;
  }
  .btnanimation{
     box-shadow: 0 0 0 4px#ffbaba;
    background-color: red;
  }
</style>