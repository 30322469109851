<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-col-7 lg-pl3 pl2 px2 lg-pt1 mx-auto border-box">
              <p v-if='this.paymentEnabled == "true"' class="p-medium gray light mt2 ls-1">1 von 4 Angaben</p>
              <p v-if='this.paymentEnabled != "true"' class="p-medium gray light mt2 ls-1">1 von 3 Angaben</p>
              <div class="flex stage-container mb3 small-border-radius">
                <div class="formstage bgcolor1"></div>
                <div class="formstage step-background-color"></div>
                <div v-if='this.paymentEnabled == "true"' class="formstage step-background-color"></div>
                <div class="formstage step-background-color"></div>
              </div>
              <h2 class="h2-5 gray condensed mb3" v-bind:class="{ error: notfilled}">Wählen Sie ein Wunschdatum für Ihren Abstrich im Testzentrum <i>{{texts.TESTZENTRUM}}</i>:</h2>
              <v-date-picker class="mb3" is-expanded v-model="date" :min-date="nextDate"  :disabled-dates='disabledDates' color="red" locale="de-de"/>
              <div class="appointment-button-holder align-center">
                <div class="mb2">   
                  <submit-button name="Weiter" :validInput="isValid" event_name="pre_submit_step_app100"/>
                </div>
                 <div class="col-12">
                  <a @click="cancelAppointment()" class="p color1 underlined" v-if="showBackButton">Zurück zur Auswahl des Testzentrum</a>  
                </div> 
              </div>
               <div class="mb4"></div>
              <p v-html="texts.ENTRYTEXT"></p>
             

            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue'
import CTASupportElement from './CTASupportElement.vue'

export default {
  name: 'AppointmentStep01',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentTestcenter: Number
  },
  data() {
    return {
      notfilled: false,
      debug: false,
      dataEvents: {
        machinecategory_selected: false
      },
      style_class: '',
      date: '',
      nextDate: '',
      disabledDates: { weekdays: [5,6] },
      //disabledDates: [new Date(2022, 9, 31)],

      isValid: false,
      showBackButton: false,
      texts: {
        TESTZENTRUM: process.env.VUE_APP_TESTZENTRUM,
        ENTRYTEXT: process.env.VUE_APP_ENTRYTEXT,
      },
      SECONDTESTCENTER: process.env.VUE_APP_SECONDTESTCENTERACTIVE,
      testcenter: 1,
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
    }
  },
  created() {
    //console.log('Szep01 - this.onBoardingAppointmentTestcenter: ' + this.onBoardingAppointmentTestcenter);
    if(this.SECONDTESTCENTER == 'true') {
        this.showBackButton = true;
    }
    //console.log('Step01 - this.SECONDTESTCENTER: ' + this.SECONDTESTCENTER);

    if(this.style != "") {
      this.style_class = this.style;
    }

    this.disabledDates.weekdays = this.setWeekDays_Testcenter1();
    this.calculateSelectedDay();


    EventBus.$on('pre_submit_step_app100', () => {
      console.log(this.date);
      if(this.date != "") {
        this.notfilled = false;
          EventBus.$emit('submit_step_app100', {
           "appointment_date": this.date
          });
      } else {
        // TODO: Alertbox nothing inserted!
        this.notfilled = true;
      }
    });

  },
  watch:{
    date: function(newValue, oldValue) {
        if(newValue != oldValue) {
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          var pickedDate = this.date
          if(pickedDate >= today || pickedDate.toDateString() == today.toDateString()){
          this.date = newValue
          this.isValid = true;
          }else{
            this.isValid = false;
          }
        }
      },
     onBoardingAppointmentTestcenter: function(newValue, oldValue) {
       console.log('AppStep01.vue: new onBoardingAppointmentTestcenter found, new: ' + newValue + ' old: ' + oldValue);

         if(newValue == 1) {
            this.texts.TESTZENTRUM = process.env.VUE_APP_TESTZENTRUM;
             this.disabledDates.weekdays = this.setWeekDays_Testcenter1();
             this.calculateSelectedDay();
          }

         if(newValue == 2) {
          this.texts.TESTZENTRUM = process.env.VUE_APP_SECOND_TESTZENTRUM;
          this.disabledDates.weekdays = this.setWeekDays_Testcenter2();
          this.calculateSelectedDay();
         }
         if(newValue == 3) {
          this.texts.TESTZENTRUM = process.env.VUE_APP_THIRD_TESTZENTRUM;
          this.disabledDates.weekdays = this.setWeekDays_Testcenter3();
          this.calculateSelectedDay();
         }
         if(newValue == 4) {
          this.texts.TESTZENTRUM = process.env.VUE_APP_FOURTH_TESTZENTRUM;
          this.disabledDates.weekdays = this.setWeekDays_Testcenter4();
          this.calculateSelectedDay();
         }
     }
      
  },
  components: {
    'submit-button' : SubmitButton,
    'cta-support-element': CTASupportElement
  },
  methods: {
    submit: function() {
        this.log('Appointment01.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    cancelAppointment(){
      EventBus.$emit('appointment_cancel_from_step01');
    }, 
    setWeekDays_Testcenter1() {
      let weekdays = [];
      
      if(process.env.VUE_APP_WEEKDAY_SATUDAY_ALLOWED == 'false') {
        weekdays.push(7);
      }
      if(process.env.VUE_APP_WEEKDAY_MONDAY_ALLOWED == 'false') {
        weekdays.push(2);
      }
      if(process.env.VUE_APP_WEEKDAY_TUESDAY_ALLOWED == 'false') {
        weekdays.push(3);
      }
      if(process.env.VUE_APP_WEEKDAY_WEDNESDAY_ALLOWED == 'false') {
        weekdays.push(4);
      }
      if(process.env.VUE_APP_WEEKDAY_THURSDAY_ALLOWED == 'false') {
        weekdays.push(5);
      }
      if(process.env.VUE_APP_WEEKDAY_FRIDAY_ALLOWED == 'false') {
        weekdays.push(6);
      }
      if(process.env.VUE_APP_WEEKDAY_SUNDAY_ALLOWED == 'false') {
        weekdays.push(1);
      }
      return weekdays;
    },
    setWeekDays_Testcenter2() {
      let weekdays = [];
      
      if(process.env.VUE_APP_SECOND_WEEKDAY_SATUDAY_ALLOWED == 'false') {
        weekdays.push(7);
      }
      if(process.env.VUE_APP_SECOND_WEEKDAY_MONDAY_ALLOWED == 'false') {
        weekdays.push(2);
      }
      if(process.env.VUE_APP_SECOND_WEEKDAY_TUESDAY_ALLOWED == 'false') {
        weekdays.push(3);
      }
      if(process.env.VUE_APP_SECOND_WEEKDAY_WEDNESDAY_ALLOWED == 'false') {
        weekdays.push(4);
      }
      if(process.env.VUE_APP_SECOND_WEEKDAY_THURSDAY_ALLOWED == 'false') {
        weekdays.push(5);
      }
      if(process.env.VUE_APP_SECOND_WEEKDAY_FRIDAY_ALLOWED == 'false') {
        weekdays.push(6);
      }
      if(process.env.VUE_APP_SECOND_WEEKDAY_SUNDAY_ALLOWED == 'false') {
        weekdays.push(1);
      }
      return weekdays;
    },
    setWeekDays_Testcenter3() {
      let weekdays = [];
      
      if(process.env.VUE_APP_THIRD_WEEKDAY_SATUDAY_ALLOWED == 'false') {
        weekdays.push(7);
      }
      if(process.env.VUE_APP_THIRD_WEEKDAY_MONDAY_ALLOWED == 'false') {
        weekdays.push(2);
      }
      if(process.env.VUE_APP_THIRD_WEEKDAY_TUESDAY_ALLOWED == 'false') {
        weekdays.push(3);
      }
      if(process.env.VUE_APP_THIRD_WEEKDAY_WEDNESDAY_ALLOWED == 'false') {
        weekdays.push(4);
      }
      if(process.env.VUE_APP_THIRD_WEEKDAY_THURSDAY_ALLOWED == 'false') {
        weekdays.push(5);
      }
      if(process.env.VUE_APP_THIRD_WEEKDAY_FRIDAY_ALLOWED == 'false') {
        weekdays.push(6);
      }
      if(process.env.VUE_APP_THIRD_WEEKDAY_SUNDAY_ALLOWED == 'false') {
        weekdays.push(1);
      }
      return weekdays;
    },
    setWeekDays_Testcenter4() {
      let weekdays = [];
      
      if(process.env.VUE_APP_FOURTH_WEEKDAY_SATUDAY_ALLOWED == 'false') {
        weekdays.push(7);
      }
      if(process.env.VUE_APP_FOURTH_WEEKDAY_MONDAY_ALLOWED == 'false') {
        weekdays.push(2);
      }
      if(process.env.VUE_APP_FOURTH_WEEKDAY_TUESDAY_ALLOWED == 'false') {
        weekdays.push(3);
      }
      if(process.env.VUE_APP_FOURTH_WEEKDAY_WEDNESDAY_ALLOWED == 'false') {
        weekdays.push(4);
      }
      if(process.env.VUE_APP_FOURTH_WEEKDAY_THURSDAY_ALLOWED == 'false') {
        weekdays.push(5);
      }
      if(process.env.VUE_APP_FOURTH_WEEKDAY_FRIDAY_ALLOWED == 'false') {
        weekdays.push(6);
      }
      if(process.env.VUE_APP_FOURTH_WEEKDAY_SUNDAY_ALLOWED == 'false') {
        weekdays.push(1);
      }
      return weekdays;
    },
    calculateSelectedDay() {
      
      let today = new Date()
      let tomorrow = new Date(today);
      this.nextDate = today;
      let daysPlus = 0;
      
      tomorrow.setDate(today.getDate() + daysPlus);
      console.log(today);
      console.log(tomorrow);
      // Find next date...
      let findingDate = true;
      while(findingDate) {
        let weekday = tomorrow.getDay() + 1;
        console.log('iteration-weekday:' + weekday);
        console.log('iteration-tomorrow:' + tomorrow);
        console.log('iteration-daysPlus:' + daysPlus);
        console.log('-------');
        if(!this.disabledDates.weekdays.includes(weekday)) {
          break;
        } else {
          daysPlus = daysPlus +1;
          let myDate = new Date(new Date().getTime()+(daysPlus*24*60*60*1000));
          tomorrow.setDate(myDate.getDate());
        }
      }
      console.log(tomorrow);
      this.date = tomorrow;

    }

    
  }
}
</script>

<style>
 
  .fw-600{
    font-weight: 600;
  }
  .appointment-button-holder{
    display: block;
  }
  @media(min-width: 1440px){
    .appointment-button-holder{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    .appointment-button-holder div{
      margin-bottom: 0;
    }

  }

</style>