<template>
  <div class="lg-px2 px1 py2 mb2 pricing-info-el bg-lighter-red" style="margin-bottom: .25rem">
    <h1 class="h4  condensed medium lh-2">{{heading}}</h1>
    <div class="p-medium mb2 extra-light pt2 lh-2 ls-1">
      {{excerpt}} 
      <span @click='showBoxContent = !showBoxContent' class="underline pointer">{{ !showBoxContent ?  'Mehr erfahren' : 'Zuklappen'}}</span>
    <div class="mt2" v-html='text' v-show='showBoxContent'></div>
    </div>
  </div>
</template>

<script>


export default {
  name:'PricingInfoEl',
  props: {
    colorClass: String,
    heading: String,
    text: String,
    excerpt: String
  },
  data(){
    return {
      showBoxContent: false
    }
  },
}


</script>

<style scoped>
.bg-lighter-red{
  background-color: #ba1414;
}
</style>