<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-col-7 lg-pl3 pl2 px2 lg-pt1 mx-auto border-box">
              <p class="p-medium gray light mt2 ls-1">3 von 4 Angaben</p>
              <div class="flex stage-container mb3 small-border-radius">
                <div class="formstage bgcolor1 pointer" @click="changeStep(step = 1001)"></div>
                <div class="formstage bgcolor1 pointer" @click="changeStep(step = 1002)"></div>
                 <div class="formstage bgcolor1"></div>
                <div class="formstage step-background-color"></div>
              </div>
              <h2 class="h2-5 gray condensed mb2 pt1" v-bind:class="{ error: notfilled}"> Leistung und Zahlungsweise wählen:</h2>
              <h2 class="h2 h2_backwards regular condensed pb3 lh-2">Sie haben dieses Datum ausgewählt:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1001)">{{onBoardingAppointmentDateDefault}}</span>
              </h2>
              <h2 class="h2 h2_backwards condensed pb3 lh-2">Sie haben diese Uhrzeit gewählt:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1002)">{{onBoardingAppointmentTimeDefault}}</span><span> Uhr</span>
              </h2>
              <p class="color1 p-medium light mb0-5">Produkt:</p>
              <div>
                <label class="dsgvo-container cwa-style pointer">
                    <input type="radio" v-model="customer_product" value="Schnelltest"  style="flex-shrink: 0;" name="customer_product">
                    <p><b>{{texts.PRODUCT1_NAME}}</b> - {{texts.PRODUCT1_PRICE}} €</p>
                </label>
                <label class="dsgvo-container cwa-style pointer" >
                    <input type="radio" v-model="customer_product" value="PCR-Test"  style="flex-shrink: 0;" name="customer_product" > 
                    <p><b>{{texts.PRODUCT2_NAME}}</b> - {{texts.PRODUCT2_PRICE}} €</p>
                </label>
              </div>

              <br>
              <div v-if="paymentEnabled == 'true'">
                <p class="color1 p-medium light mb0-5">Zahlungsweise:</p>
                <div>
                  <!-- <label class="dsgvo-container cwa-style">
                      <input type="radio" v-model="customer_payment" value="Paypal" name="customer_payment"> 
                      <p><b>{{texts.PAYMENT1_NAME}}</b><br>
                      {{texts.PAYMENT1_DESC}}</p>
                  </label>
                  <label class="dsgvo-container cwa-style pointer" >
                      <input type="radio" v-model="customer_payment" value="EC-Lastschrift (BAR)"  style="flex-shrink: 0;" name="customer_payment"> 
                      <p><b>{{texts.PAYMENT2_NAME}}</b><br>
                      {{texts.PAYMENT2_DESC}}</p>
                  </label> -->

                  <!-- Kostenpflichtig -->
                   <label class="dsgvo-container cwa-style">
                      <input type="radio" v-model="customer_payment" value="Vollzahler" name="customer_payment"> 
                      <p><b>{{texts.PAYMENT1_NAME}}</b><br>
                      {{texts.PAYMENT1_DESC}}</p>
                  </label>

                  <!-- <div class="ml3 mb3">
                    <p class="underline pointer mb3" @click="show_reduced_info_text = !show_reduced_info_text">{{show_reduced_info_text ? 'Weniger anzeigen' : 'Wie bekomme ich einen ermäßigten Schnelltest?'}}</p>
                    <div v-show='show_reduced_info_text' v-html='texts.PAYMENT_REDUCED_INFO_TEXT'></div>
                  </div> -->


                  <!-- Kostenfrei -->
                  <!--label class="dsgvo-container cwa-style pointer" >
                      <input id="kostenfreiRadio" type="radio" v-model="customer_payment" value="Kostenfrei" name="customer_payment" style="flex-shrink: 0;" :disabled="customer_product == 'PCR-Test'"> 
                      <p><b>{{texts.PAYMENT3_NAME}}</b> <br><a class="info" @click="showModal">Mehr Informationen</a><br>
                      {{texts.PAYMENT3_DESC}}</p>
                  </label //-->

                  <div class="reason-box" v-if="customer_payment == 'Ermaessigt'">
                    <p class="reason-title">Bitte wählen Sie einen der folgenden Gründe aus:</p>
                  <label class="dsgvo-container reason cwa-style pointer" >
                    <input type="radio" v-model="discount_reason" value="discount_reason_1" name="free_reason" style="flex-shrink: 0;">
                    <p>{{texts.VUE_APP_REDUCED_INDOOR_EVENT}}</p>
                  </label>
                  <label class="dsgvo-container reason cwa-style pointer" >
                    <input type="radio" v-model="discount_reason" value="discount_reason_2" name="free_reason" style="flex-shrink: 0;">
                    <p>{{texts.VUE_APP_REDUCED_CONTACT_PEOPLE_60Y}}</p>
                  </label>
                  <label class="dsgvo-container  reason cwa-style pointer" >
                    <input type="radio" v-model="discount_reason" value="discount_reason_3" name="free_reason" style="flex-shrink: 0;">
                    <p>{{texts.VUE_APP_REDUCED_PEOPLE_PREV_ILL}}</p>
                  </label>
                  <label class="dsgvo-container reason cwa-style pointer" >
                    <input type="radio" v-model="discount_reason" value="discount_reason_4" name="free_reason" style="flex-shrink: 0;">
                    <p>{{texts.VUE_APP_REDUCED_REPORT_CORONA_APP}}</p>
                  </label>
                  </div>

                  <div class="reason-box" v-if="customer_payment == 'Kostenfrei'">
                    <p class="reason-title">Bitte wählen Sie einen der folgenden Gründe aus:</p>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_1" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_PEOPLE_5Y}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_2" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_MEDICAL_CONTRAINDICATION}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_3" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_CLINICAL_STUDIE}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_4" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_FINISH_QUARANTINE}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_5" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_VISIT_HOSPITAL}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_6" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_PEOPLE_DISABILITIES_AND_EMPLOYEES}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_7" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_CAREGIVER}}</p>
                    </label>
                    <label class="dsgvo-container reason cwa-style pointer" >
                      <input type="radio" v-model="free_reason" value="free_reason_8" name="free_reason" style="flex-shrink: 0;">
                      <p>{{texts.VUE_APP_FREE_CONTACT_POS_SAME_HOUSE}}</p>
                    </label>
                  </div>

                  <!-- <div class="ml3 mb3">
                    <p class="underline pointer mb3" @click="show_free_info_text = !show_free_info_text" >{{show_free_info_text ? 'Weniger anzeigen' : 'Wie bekomme ich einen kostenlosen Schnelltest?'}}</p>
                    <div v-show='show_free_info_text' v-html='texts.PAYMENT_FREE_INFO_TEXT'></div>
                  </div> -->

                  <modal v-show="isModalVisible" @close="closeModal" />
                  <modal2 v-show="isModalVisible2" @close="closeModal" />
                </div>
              </div>
              <div class="appointment-button-holder align-center">
                <div class="mb2">   
                  <submit-button name="Weiter" :validInput="isValid" event_name="pre_submit_step_app300"/>
                </div>
              </div>
              <div class="mb4"></div>
            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js"
import SubmitButton from './SubmitButton.vue'
import CTASupportElement from './CTASupportElement.vue'
import modal from './ModalInfo.vue'
import axios from 'axios'
import modal2 from './ModalInfo2.vue'

export default {
  name: 'AppointmentStep03',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentDateDefault: String,
    onBoardingAppointmentTimeDefault: String,
    onBoardingAppointmentTestcenter: Number,

  },
  data() {
    return {
      notfilled: false,
      debug: false,
      style_class: '',
      time: '',
      date:'',
      isValid: true,
      customer_product: 'Schnelltest',
      customer_payment: 'Vollzahler',
      valid1: false,
      valid2: false,
      isModalVisible: false,
      isModalVisible2: false,

      avail_dates: [],
      allBooked: false,
      error_message: '',

      APIENDPOINT: process.env.VUE_APP_APIENDPOINT,

      show_free_info_text: false,
      show_reduced_info_text: false,

      discount_reason: '',
      free_reason:'',
      
      texts: {
        PRODUCT1_NAME: process.env.VUE_APP_PRODUCT1_NAME,
        PRODUCT2_NAME: process.env.VUE_APP_PRODUCT2_NAME,  
        PRODUCT1_PRICE: process.env.VUE_APP_PRODUCT1_PRICE,
        PRODUCT2_PRICE: process.env.VUE_APP_PRODUCT2_PRICE,

        PAYMENT1_NAME: process.env.VUE_APP_PAYMENT1_NAME,
        PAYMENT2_NAME: process.env.VUE_APP_PAYMENT2_NAME,
        PAYMENT3_NAME: process.env.VUE_APP_PAYMENT3_NAME,

        PAYMENT1_DESC: process.env.VUE_APP_PAYMENT1_DESC,
        PAYMENT2_DESC: process.env.VUE_APP_PAYMENT2_DESC,
        PAYMENT3_DESC: process.env.VUE_APP_PAYMENT3_DESC,

        PAYMENT_FREE_INFO_TEXT: process.env.VUE_APP_PRICING_INFO_FREE_PRICING_TEXT_02,
        PAYMENT_REDUCED_INFO_TEXT: process.env.VUE_APP_PRICING_INFO_3EURO_PRICING_TEXT_02,

        QUESTION_FOR_DETAIL_CLAIM: process.env.VUE_APP_QUESTION_FOR_DETAIL_CLAIM,

        VUE_APP_REDUCED_INDOOR_EVENT:process.env.VUE_APP_REDUCED_INDOOR_EVENT,
        VUE_APP_REDUCED_CONTACT_PEOPLE_60Y:process.env.VUE_APP_REDUCED_CONTACT_PEOPLE_60Y,
        VUE_APP_REDUCED_PEOPLE_PREV_ILL:process.env.VUE_APP_REDUCED_PEOPLE_PREV_ILL,
        VUE_APP_REDUCED_REPORT_CORONA_APP:process.env.VUE_APP_REDUCED_REPORT_CORONA_APP,

        VUE_APP_FREE_PEOPLE_5Y:process.env.VUE_APP_FREE_PEOPLE_5Y,
        VUE_APP_FREE_MEDICAL_CONTRAINDICATION:process.env.VUE_APP_FREE_MEDICAL_CONTRAINDICATION,
        VUE_APP_FREE_CLINICAL_STUDIE:process.env.VUE_APP_FREE_CLINICAL_STUDIE,
        VUE_APP_FREE_FINISH_QUARANTINE:process.env.VUE_APP_FREE_FINISH_QUARANTINE,
        VUE_APP_FREE_VISIT_HOSPITAL:process.env.VUE_APP_FREE_VISIT_HOSPITAL,
        VUE_APP_FREE_PEOPLE_DISABILITIES_AND_EMPLOYEES:process.env.VUE_APP_FREE_PEOPLE_DISABILITIES_AND_EMPLOYEES,
        VUE_APP_FREE_CAREGIVER:process.env.VUE_APP_FREE_CAREGIVER,
        VUE_APP_FREE_CONTACT_POS_SAME_HOUSE:process.env.VUE_APP_FREE_CONTACT_POS_SAME_HOUSE
      },
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
    }
  },
  created() {
    if(this.style != "") {
      this.style_class = this.style;
    }
    
    EventBus.$on('submit_step_app100', (payload) => {
        console.log(payload)
        var chosenDate = payload.appointment_date;
        var dd = chosenDate.getDate(); 
        var mm = chosenDate.getMonth() + 1; 
        var yyyy = chosenDate.getFullYear(); 
        if (dd < 10) { 
            dd = '0' + dd; 
        } 
        if (mm < 10) { 
            mm = '0' + mm; 
        } 
        var fancyDate = dd + '.' + mm + '.' + yyyy;
        this.date = fancyDate;
        
        let createURL = process.env.VUE_APP_APIENDPOINT + '/onboarding/appointments/checkavailability';
        if(this.onBoardingAppointmentTestcenter == 2) {
          createURL = process.env.VUE_APP_SECOND_APIENDPOINT + '/onboarding/appointments/checkavailability';
        }
        if(this.onBoardingAppointmentTestcenter == 3) {
          createURL = process.env.VUE_APP_THIRD_APIENDPOINT + '/onboarding/appointments/checkavailability';
        }
        if(this.onBoardingAppointmentTestcenter == 4) {
          createURL = process.env.VUE_APP_FOURTH_APIENDPOINT + '/onboarding/appointments/checkavailability';
        }
        let currentObj = this;

        axios.post(createURL, {
          'date': this.date
        }).then(function (response) {
          currentObj.apiResponse = response.data;
          console.log( response.data);
          if(currentObj.apiResponse.okay) {
            console.log('found dates...');
            if(!currentObj.apiResponse.allBooked) {
              console.log('found dates... dates available!');
              currentObj.avail_dates = currentObj.apiResponse.dates;
              currentObj.allBooked = false;
            } else {
              currentObj.allBooked = true;
              
              console.log('found dates... NO dates available!');
               currentObj.error_message = currentObj.apiResponse.message;

            }
          } else {
            currentObj.allBooked = true;
            console.log('found dates... no!');
            currentObj.error_message = currentObj.apiResponse.message;
          }
        })
        .catch(function (error) {
          currentObj.log('catch Function of AJAX-Called... error()');
          currentObj.log(error);
          currentObj.apiResponse = error;
        });

        
    });
    EventBus.$on('pre_submit_step_app300', () => {
      console.log('Appointment03.vue: Emitting Event submit_step_app300');
      if(this.customer_payment == "Vollzahler"){
        EventBus.$emit('submit_step_app300', {
          'app_customer_product': this.customer_product,
          'app_customer_payment': this.customer_payment,
          'app_customer_payment_reason': "",
        });
      }

      if(this.customer_payment == "Ermaessigt"){
        EventBus.$emit('submit_step_app300', {
          'app_customer_product': this.customer_product,
          'app_customer_payment': this.customer_payment,
          'app_customer_payment_reason': this.discount_reason,
        });
      }

      if(this.customer_payment == "Kostenfrei"){
        EventBus.$emit('submit_step_app300', {
          'app_customer_product': this.customer_product,
          'app_customer_payment': this.customer_payment,
          'app_customer_payment_reason': this.free_reason,
        });
      }
    });
  },

  components: {
    'submit-button' : SubmitButton,
    'cta-support-element': CTASupportElement,
    'modal': modal,
    'modal2': modal2
  },
  methods: {
    submit: function() {
        this.log('Appointment02.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    cancelAppointment(){
      EventBus.$emit('appointment_cancel');
    },
    changeStep(step){
      EventBus.$emit('changeStep', {
            'step_number': step
          });
    },
    paymentSelection(){
      switch (this.customer_payment) {
        case 'Vollzahler':
            return true;

        case 'Ermaessigt':
          if(this.discount_reason != ''){
            return true;
          }else{
            return false;
          }

        case 'Kostenfrei':
          if(this.free_reason != ''){
            return true;
          }else{
            return false;
          }
      
        default:
          break;
      }
    },    
    checkValidation(){
      if(this.customer_product == '') {
        this.valid1 = false;
      }else{
        this.valid1 = true;
      }
      console.log(this.customer_payment);
      if(this.paymentSelection()) {
        this.valid2 = true;
      }else{
        this.valid2 = false;
      }
      if(this.valid1 && this.valid2) {
        this.isValid = true;
      }else{
        this.isValid = false;
      }
    },
    showModal() {
      this.isModalVisible = true;
      document.documentElement.style.overflow = 'hidden'
    },
    showModal2() {
      this.isModalVisible2 = true;
      document.documentElement.style.overflow = 'hidden'
    },
    closeModal() {
      this.isModalVisible = false;
      this.isModalVisible2 = false;
      document.documentElement.style.overflow = 'auto'
    }
  },
  watch: {
    customer_product: function (newValue, oldValue){
      if(newValue != oldValue) {
        if(this.customer_product == "PCR-Test" && this.customer_payment == "Kostenfrei"){
          document.getElementById("kostenfreiRadio").checked = false;
          this.customer_payment = "";
        }
        this.checkValidation();
      }
    },
    customer_payment: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation();
      }
    }, 
    discount_reason: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation();
      }
    },   
    free_reason: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation();
      }
    },   
  }
}
</script>

<style scoped>

label.dsgvo-container.cwa-style {
    padding: 1rem;
    display: flex;
}

label.dsgvo-container.cwa-style input[type="radio"] {
    margin-right: 1rem;
}
.h2_backwards {
  color: #9395a6;
  font-size: 20px;
}

/* The container */
.dsgvo-container {
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

.info{
  text-decoration: underline;
  cursor: pointer;
}

.reason-box{
  padding: 1rem;
  background-color: #ebebeb;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.reason{
  color: #000;
}

.reason-title{
  margin-bottom: 1rem;
}
</style>