<template>
      <div>
        <div v-if="INFOBOX_ACTIVE=='true'" class="alertbox-info max-width-6 mx-auto mt4 px2">
              <div id="dev-hint">
                  {{INFOBOX_TEXT}}
                
              </div>
        </div>
        <pricing-info/>
       </div>
</template>

<script>
import PricingInfoNew from './PricingInfoNew.vue'
export default {
  name: "Infobox",
  data(){
    return {
      
      INFOBOX_TEXT: process.env.VUE_APP_INFOBOX_TEXT,
      INFOBOX_ACTIVE: process.env.VUE_APP_INFOBOX_ACTIVE,
      
    }
  },
  components: {
    'pricing-info': PricingInfoNew
  }
};

</script>