<template>
    <div id="app"> 
        <div class="step-holder mx-auto step-1001" v-show="step == 999">
            <appointment-step-00
              :appointment="appointment"
              
            />
        </div>
        <div class="step-holder mx-auto step-1001" v-show="step == 1001">
            <appointment-step-01
              :appointment="appointment"
              :onBoardingAppointmentTestcenter="testcenter"

            />
        </div>
        <div class="step-holder mx-auto step-1002" v-show="step == 1002">
            <appointment-step-02
              :appointment="appointment"
              :onBoardingAppointmentDateDefault="appointmentData.customer_appointment_date"
              :onBoardingAppointmentTestcenter="testcenter"
            />
        </div>
        <div class="step-holder mx-auto step-1003" v-show="step == 1003">
            <appointment-step-03
              :appointment="appointment"
              :onBoardingAppointmentDateDefault="appointmentData.customer_appointment_date"
              :onBoardingAppointmentTimeDefault="appointmentData.customer_appointment_time"
              :onBoardingAppointmentTestcenter="testcenter"
            />
        </div>
        <div class="step-holder mx-auto step-1004" v-show="step == 1004">
            <appointment-step-04
              :appointment="appointment"
              :onBoardingAppointmentDateDefault="appointmentData.customer_appointment_date"
              :onBoardingAppointmentTimeDefault="appointmentData.customer_appointment_time"
              :onBoardingAppointmentProductDefault="appointmentData.customer_appointment_product"
              :onBoardingAppointmentPaymentDefault="appointmentData.customer_appointment_payment"
              :onBoardingAppointmentTestcenter="testcenter"
            />
        </div>
        <div class="step-holder mx-auto step-1005" v-show="step == 1005">
            <appointment-step-05
              :appointment="appointment"
              :onBoardingAppointmentDateDefault="appointmentData.customer_appointment_date"
              :onBoardingAppointmentTimeDefault="appointmentData.customer_appointment_time"
              :apiResponse="apiResponse"
              :onBoardingAppointmentImageSRC="appointmentData.imageSRC"
              :onBoardingAppointmentTestcenter="testcenter"
            />
        </div>
        <div class="step-holder mx-auto step-1006" v-if="step == 1006">
            <appointment-step-06
             :aD="appointmentData"
             :apiR="apiResponse" 
             :testcenter="testcenter"/>
        </div>
        <div class="step-holder mx-auto step-1007" v-show="step == 1007">
            <appointment-step-07
             :apiResponse="apiResponse" 
             />
        </div>

        <div class="step-holder mx-auto step-1004" v-show="step == 1013">
            <appointment-step-04-1
            />
        </div>
        <div class="step-holder mx-auto step-1004" v-show="step == 2000">
            <cancel-request/>
        </div>


    </div>
</template>

<script>


import AppointmentStep00 from './AppointmentStep00.vue'
import AppointmentStep01 from './AppointmentStep01.vue'
import AppointmentStep02 from './AppointmentStep02.vue'
import AppointmentStep03 from './AppointmentStep03.vue'
import AppointmentStep04 from './AppointmentStep04.vue'
import AppointmentStep05 from './AppointmentStep05.vue'
import AppointmentStep04_1 from './AppointmentStep04_1.vue'
import AppointmentStep06 from './AppointmentStep06.vue'
import AppointmentStep07 from './AppointmentStep07.vue'
import CancelRequest from './CancelRequest.vue'


import { EventBus } from "@/event-bus";
import axios from 'axios'

export default {
  name: "app",
  props: ['source','counterpart'],
  data() {
    return {
      debug: false, 
      step: 1001, // INFO: Set this to a diffent number to see further steps, e.g. 100 or 200.
      validate: false,
      testcenter: 1,
      onboardingData: {
        opportunities_area: "",
        opportunities_producer: "",
        opportunities_type: "",
        opportunities_year: '',
        opportunities_nettoprice: "",
        opportunities_value: "",
        opportunities_amount: "",
        opportunities_charge: "",
        opportunities_category_id: "",
        customers_company: "",
        customers_company_additional: "",
        customers_address: "",
        customers_city: "",
        customers_street: "",
        customers_postal_code: "",
        customers_prename: "",
        customers_surname: "",
        customers_gender: "",
        customer_contact_emailadress: "",
        customer_contact_phonenumber: "",
        tracking_approved: "false"
      },
      appointmentData: {
        customer_appointment_center: "",
        customer_appointment_date: "",
        customer_appointment_time: "",
        customer_appointment_product: "",
        customer_appointment_payment: "",
        customer_appointment_payment_reason: "",
        customer_appointment_name: "",
        customer_appointment_email: "",
        customer_appointment_phonenumber: "",
        customer_appointment_birthday: "",
        customer_appointment_postalcode: "",
        customer_appointment_address: "",
        customer_appointment_dsgvo: "",
        customer_appointment_coronawarnapp: "",
        customer_appointment_coronawarnapp_type: "",
        customer_appointment_sign: "",
        customer_appointment_bookingcode: "",
        customer_appointment_crypt: "",
        imageSRC: "",
        tracking_approved: "false"
      },
      appointment: false,
      apiResponse: {},
      STEP_SELECT_MACHINECATEGORY: 100,
      STEP_SELECT_MANUFACTURER: 200,

      APIENDPOINT: process.env.VUE_APP_APIENDPOINT,
      SYSTEMTITLE: process.env.VUE_APP_SYSTEMTITLE,
      SECONDTESTCENTER: process.env.VUE_APP_SECONDTESTCENTERACTIVE,
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
    };
  },
  created: function () {
    if(this.SECONDTESTCENTER == 'true') {
        this.step = 999;
    }
    if(this.SYSTEMTITLE != "") {
        let newTitle = this.SYSTEMTITLE;
        if (document.title != newTitle) {
            document.title = newTitle;
            document.querySelector('meta[name="description"]').setAttribute("content", newTitle);

        }
    }
   

    let uri = window.location.href.split('?');
    if (uri.length == 2)
    {
      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v){
        tmp = v.split('=');
        if(tmp.length == 2)
        getVars[tmp[0]] = tmp[1];
      });
      this.log('App.vue: Get-Parameters BEGINN')
      this.log(getVars);
      this.log('App.vue: Get-Parameters END')

    }
    
    EventBus.$on('appointment_request', () => { // Keine payload! 
        console.log('App received appointment_request')
        this.log('App.vue: Event received: appointment_request');
        this.scrollToTop();
        this.reprint(1001);
        this.appointment = true;
        
    });
    EventBus.$on('submit_step_app000', (payload) => {
        console.log('App received submit_step_app000')
        this.log('App.vue: Event received: App received submit_step_app000');

        if(payload.appointment_center == 'testcenter_01') {
          this.testcenter = 1;
        }
        if(payload.appointment_center == 'testcenter_02') {
          this.testcenter = 2;
        }
        if(payload.appointment_center == 'testcenter_03') {
          this.testcenter = 3;
        }
        if(payload.appointment_center == 'testcenter_04') {
          this.testcenter = 4;
        }
        if(payload.appointment_center != undefined){
          this.appointmentData.customer_appointment_center = payload.appointment_center
        }
        console.log('App.Vue: Setting Testcenter to: ' + this.testcenter);
       
        this.scrollToTop();
        this.reprint(1001);
        
    });
    EventBus.$on('submit_step_app100', (payload) => {
        console.log(payload)
        var chosenDate = payload.appointment_date;
        var dd = chosenDate.getDate(); 
        var mm = chosenDate.getMonth() + 1; 
        var yyyy = chosenDate.getFullYear(); 
        if (dd < 10) { 
            dd = '0' + dd; 
        } 
        if (mm < 10) { 
            mm = '0' + mm; 
        } 
        var fancyDate = dd + '.' + mm + '.' + yyyy;
        this.appointmentData.customer_appointment_date = fancyDate;
        this.log('App.vue: Event received: submit_step_app100');
        this.scrollToTop();
        this.reprint(1002);
       
        
    });

    EventBus.$on('submit_step_app200', (payload) => {
        console.log(payload)
        this.log('App.vue: Event received: submit_appointment_step_app200');
        if(payload.appointment_time != undefined){
          this.appointmentData.customer_appointment_time = payload.appointment_time
        }
        this.scrollToTop();
        if(this.paymentEnabled == "true"){
          this.reprint(1003);              
        }else{
          EventBus.$emit('submit_step_app300', {
            'app_customer_product': 'none',
            'app_customer_payment': 'none',
          });
        }
    });

    EventBus.$on('submit_step_app300', (payload) => {
        console.log(payload)
        this.log('App.vue: Event received: submit_appointment_step_app300');
        this.appointmentData.customer_appointment_product = payload.app_customer_product;
        this.appointmentData.customer_appointment_payment = payload.app_customer_payment;
        this.appointmentData.customer_appointment_payment_reason = payload.app_customer_payment_reason;
        this.scrollToTop(); 
        this.reprint(1004);
        
    });
    EventBus.$on('submit_step_app400', (payload) => {
        
        this.appointmentData.customer_appointment_birthday = payload.app_customers_birthday;
        this.appointmentData.customer_appointment_name = payload.app_customers_name;
        this.appointmentData.customer_appointment_email = payload.app_customers_email;
        this.appointmentData.customer_appointment_phonenumber = payload.app_customer_contact_phonenumber;
        this.appointmentData.customer_appointment_address = payload.app_customer_contact_address;
        this.appointmentData.customer_appointment_postalcode = payload.app_customer_contact_postalcode;
        this.appointmentData.customer_appointment_dsgvo = payload.app_customers_dsgvo;
        this.appointmentData.customer_appointment_coronawarnapp = payload.app_customer_coronawarnapp;
        this.appointmentData.customer_appointment_coronawarnapp_type = payload.app_customer_coronawarnapp_type;
        
        this.log('App.vue: Event received: submit_step_app400');
        this.scrollToTop();
        this.makeAppointmentRequest();
        
    });
    EventBus.$on('submit_step_app500', () => {
        
        this.log('App.vue: Event received: submit_step_app500');
        this.scrollToTop();
        this.reprint(1006)
        EventBus.$emit('resizeCanvas');
        
    });
    EventBus.$on('submit_step_app600', () => {
        
        this.log('App.vue: Event received: submit_step_app600');
        this.scrollToTop();
        this.reprint(1007);

        
    });

    EventBus.$on('start_onboarding', () => { // Kein payload!
        this.log('App.vue: Event received: start_onboarding');
        this.scrollToTop();
        this.reprint(100);
    });
    EventBus.$on('dataRefresh', () => { // Kein payload!
        this.log('App.vue: Event received: dataRefresh');
    });
    EventBus.$on('changeStep', (payload) => { 
        console.log('App received changeStep')
        this.log('App.vue: Event received: changeStep');
        this.scrollToTop();
        this.reprint(payload.step_number);
    });
    EventBus.$on('appointment_cancel', () => { // Keine payload! 
        console.log('App received appointment_cancel')
        this.log('App.vue: Event received: appointment_cancel');
        this.scrollToTop();
        this.reprint(1001);
        this.appointment = false;
    });
    EventBus.$on('appointment_cancel_from_step01', () => { // Keine payload! 
        console.log('App received appointment_cancel_from_step01')
        this.log('App.vue: Event received: appointment_cancel_from_step01');
        this.scrollToTop();
        this.reprint(999);
        EventBus.$emit('resetForm');

    });
    EventBus.$on('cancel_request', () => { // Keine payload! 
        console.log('App received cancel_request')
        this.log('App.vue: Event received: cancel request');
        this.scrollToTop();
        this.reprint(2000);
        EventBus.$emit('resetForm');

    });
    
    this.log('Vue has been created...Source: ' +  this.source + " Counterpart:"  + this.counterpart);

    
  },
  components: {

    'appointment-step-00' : AppointmentStep00,
    'appointment-step-01' : AppointmentStep01,
    'appointment-step-02' : AppointmentStep02,
    'appointment-step-03' : AppointmentStep03,
    'appointment-step-04' : AppointmentStep04,
    'appointment-step-05' : AppointmentStep05,
    'appointment-step-06' : AppointmentStep06,
    'appointment-step-07' : AppointmentStep07,
    'appointment-step-04-1' : AppointmentStep04_1,
    'cancel-request' : CancelRequest
   },
  methods: {
    reprint: function (newstep) {

        if(newstep == undefined || newstep == '') {
            this.log('ERROR: App.vue: reprint() called with undefined newstep!')
        } else {
            
            EventBus.$emit('dataRefresh', {
                'onboardingData': this.onboardingData
            });

            this.log('App.vue: reprint().called()');
            this.log('App.vue: reprint() Old-Step: ' + this.step);
            this.step = newstep;
            this.log('App.vue: reprint() New-Step: ' + newstep);
        }

        this.log('BOC Collected Data:');
        this.log(this.onboardingData)
        this.log('EOC Collected Data:');
    },
    log: function (obj) {
      if(this.debug) {
        console.log(obj)
      }
    },
   

     makeAppointmentRequest() {
      //SEND TO URL
      let createURL = process.env.VUE_APP_APIENDPOINT + '/onboarding/appointments/send';
      if(this.testcenter == 2) {
          createURL = process.env.VUE_APP_SECOND_APIENDPOINT +  '/onboarding/appointments/send';
      }
      if(this.testcenter == 3) {
          createURL = process.env.VUE_APP_THIRD_APIENDPOINT +  '/onboarding/appointments/send';
      }
      if(this.testcenter == 4) {
          createURL = process.env.VUE_APP_FOURTH_APIENDPOINT +  '/onboarding/appointments/send';
      }
      
      if(process.env.VUE_APP_SHOW_PAYMENT_OPTION=='true') {
        this.appointmentData.payment_workflowneeded = true;
      } else {
        this.appointmentData.payment_workflowneeded = false;
      }

      let currentObj = this;

      axios.post(createURL, {
        appointmentData: this.appointmentData
      }).then(function (response) {
        currentObj.apiResponse = response.data;
        if(currentObj.apiResponse.cwaActive) {
          currentObj.appointmentData.imageSRC = response.data.cwaData.imageSRC;
        }
        currentObj.log('Then Function of AJAX-Called... called()');
        currentObj.log(currentObj.apiResponse);
        currentObj.reprint(1005); // Alles ist fein.
        if(currentObj.apiResponse.requestBlocked) {
          currentObj.reprint(1013); // Nicht so fein.
        } else { 
          currentObj.reprint(1005); // Alles ist fein.
          EventBus.$emit('submit_step_downloadlink', {
                'onBoardingAppointmentLinkTask': currentObj.apiResponse.downloadLinkTask
            });
        }
      })
      .catch(function (error) {
        currentObj.log('catch Function of AJAX-Called... error()');
        currentObj.log(error);
        currentObj.apiResponse = error;
      });
     },

    scrollToTop() {
      window.scrollTo(0,0);
    },

  },
 
  watch: {
      flightcountselection_trigger: function () {
        this.log('App.vue: flightcountselection_trigger.changed();')
      }
  }
};
</script>
<style>
.pointer{
  cursor: pointer
}
.step-background-color{
  background-color: #ebebeb
}

input[type="tel" i] {
    font-size: 20px;
}

@media(min-width: 768px){
  .step-holder{
    max-width: 704px;
  }
}
@media(min-width:1024px){
  .step-holder{
    max-width: 900px;
  }
}
@media(min-width:1440px){
  .step-holder{
    max-width: 1280px;
  }
}

</style>
