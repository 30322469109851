<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-pt1 mx-auto px2 border-box mb3">
              <!-- <a class="mb3 underline pointer" @click="changeStep(1005)">Zurück</a> -->
              <h1 class="my2">Digitales Formular</h1>
              <h2 class="p-big mb3" style="line-height: 1.3">
                {{texts.FORM_INTRO}}
              </h2>
              <div class="mb3">
                <div class="flex p1" style="gap: 2rem;">
                  <p class="col-4 bold">Datum:</p>
                  <p class="col-8">{{aD.customer_appointment_date}}</p>
                </div>
                <div class="flex p1 bg-lgray" style="gap: 2rem;">
                <p class="col-4 bold">Zeit:</p>
                <p class="col-8">{{aD.customer_appointment_time}}</p>
                </div>
                <div class="flex p1" style="gap: 2rem;">
                <p class="col-4 bold">Name:</p>
                <p class="col-8">{{aD.customer_appointment_name}}</p>
                </div>
                <div class="flex p1 bg-lgray" style="gap: 2rem;">
                <p class="col-4 bold">Straße, Hausnummer:</p>
                <p class="col-8">{{aD.customer_appointment_address}}</p>
                </div>
                <div class="flex p1" style="gap: 2rem;">
                <p class="col-4 bold">PLZ, Wohnort:</p>
                <p class="col-8">{{aD.customer_appointment_postalcode}}</p>
                </div>
                <div class="flex p1 bg-lgray" style="gap: 2rem;">
                <p class="col-4 bold">Geburtsdatum:</p>
                <p class="col-8">{{aD.customer_appointment_birthday}}</p>
                </div>
                <div class="flex p1" style="gap: 2rem;">
                <p class="col-4 bold">Mobilnummer:</p>
                <p class="col-8">{{aD.customer_appointment_phonenumber}}</p>
                </div>
              </div>
              <h2 class="p-big mb2" style="line-height: 1.3">
                Einwilligungserklärung über die Verarbeitung von personenbezogenen Daten:
              </h2>
              <p class="mb4">
                {{texts.AGREEMENT_PERSONAL_DATA}}
              </p>

              <!-- 4a -->
              <label class="dsgvo-container" v-show="apiR.paymentType == 'Kostenfrei' || apiR.paymentType == 'Ermaessigt'">
                  <input type="checkbox" v-model="check1" @click="check1 = !check1" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check1">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                 {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_1}} *
              </label>

              <!-- Datenschutz -->
              <label class="dsgvo-container">
                  <input type="checkbox" v-model="check2" @click="check2 = !check2" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check2">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                  {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_2}} *
              </label>

              <!-- Übermittlung -->
              <label class="dsgvo-container">
                  <input type="checkbox" v-model="check3" @click="check3 = !check3" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check3">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                  {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_3}} *
              </label>

              <!-- Hinweise -->
              <label class="dsgvo-container" v-show="apiR.paymentType == 'Kostenfrei' || apiR.paymentType == 'Ermaessigt'">
                  <input type="checkbox" v-model="check4" @click="check4 = !check4" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check4">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                  {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_4}} *
              </label>

              <!-- Einwilligung Datenverarbeitung -->
              <label class="dsgvo-container" v-show="apiR.paymentType == 'Kostenfrei' || apiR.paymentType == 'Ermaessigt'">
                  <input type="checkbox" v-model="check5" @click="check5 = !check5" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check5">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                 {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_5}} *
              </label>

              <!-- Zusätzliche  Checkbox für Ermäßigung oder Kostenlos -->
              <label class="dsgvo-container" v-show="apiR.paymentType == 'Kostenfrei' || apiR.paymentType == 'Ermaessigt'">
                  <input type="checkbox" v-model="check6" @click="check6 = !check6" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check6">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                 {{apiR.paymentType == 'Kostenfrei' || apiR.paymentNeeded == false ? texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_7 : ''}}{{apiR.paymentType == 'Ermaessigt' ? texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_6 : ''}} *
              </label>

              <button v-if="!check1 || !check2 || !check3 || !check4 || !check5 || !check6" class="btn std white" @click="selectAll()" style="background-color: red; padding: .5rem 1rem">Alles ankreuzen</button>
              
              <p class="my3 bold">{{texts.VUE_APP_FORM_CONTINUATION_VALID_DOCUMENT}}</p>
              <p class="p-big bold">Digitale Unterschrift <span v-if="!isOver18">eines Erziehungsberechtigten</span> *</p>
              <sketchpad :canvasHeight="300" />
              <p class="mt3">Mit * markierte Felder sind Pflichtfelder</p>
              <div class="mt3">
                <submit-button name="Abschicken" :validInput="isValid" event_name="pre_pre_submit_step_app600" :btnAnimation="false" />
              </div>
            </div>
    </div>
</template>

<script>

// 567648000 <-- 18 years in seconds

import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue'
import Sketchpad from './Sketchpad.vue'
import axios from "axios";

export default {
  name: 'AppointmentStep06',
  props: {
    aD: Object,
    apiR: Object,
    testcenter: Number
  },
  data() {
    return {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      check6: false, //conditional for reduced or free tests
      isValid: false,
      signature: '',
      texts: {
        FORM_INTRO: process.env.VUE_APP_FORM_CONTINUATION_INTRO,
        AGREEMENT_PERSONAL_DATA: process.env.VUE_APP_FORM_CONTINUATION_AGREEMENT_PERSONAL_DATA,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_1: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_1,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_2: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_2,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_3: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_3,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_4: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_4,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_5: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_5,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_6: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_6,
        VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_7: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_7,
        VUE_APP_FORM_CONTINUATION_VALID_DOCUMENT: process.env.VUE_APP_FORM_CONTINUATION_VALID_DOCUMENT,
      }
    }
  },
  created(){
    let that = this
    EventBus.$on('send_sketch_data', (payload) => {
      that.signature = payload.sketch_data;
      EventBus.$emit('pre_submit_step_app600');
    });
    EventBus.$on('broadcast_sketchState', (payload) => {
      console.log('received sketchState')
        that.signature = payload.sketchState
        console.log(that.signature)
        that.checkValid();
    });
    EventBus.$on('pre_pre_submit_step_app600', () => {
        EventBus.$emit('request_sketch');
    })
    EventBus.$on('pre_submit_step_app600', () => {
      console.log('received pre_submit_step_app600')

    let createURL = process.env.VUE_APP_APIENDPOINT + '/onboarding/appointments/signage/' + that.apiR.appointment_bookingcode + '/' + that.apiR.appointment_crypt + '/do'
    console.log('CHECKING APIENDPOINT')
    console.log(createURL)
     if(this.testcenter == 2) {
       console.log('testcenter = 2')
          createURL = process.env.VUE_APP_SECOND_APIENDPOINT + '/onboarding/appointments/signage/' + that.apiR.appointment_bookingcode + '/' + that.apiR.appointment_crypt + '/do'
          console.log(createURL)
      }
      if(this.testcenter == 3) {
         console.log('testcenter = 3')
          createURL = process.env.VUE_APP_THIRD_APIENDPOINT +  '/onboarding/appointments/signage/' + that.apiR.appointment_bookingcode + '/' + that.apiR.appointment_crypt + '/do'
          console.log(createURL)
      }
      if(this.testcenter == 4) {
         console.log('testcenter = 4')
          createURL = process.env.VUE_APP_FOURTH_APIENDPOINT +  '/onboarding/appointments/signage/' + that.apiR.appointment_bookingcode + '/' + that.apiR.appointment_crypt + '/do'
          console.log(createURL)
      }
      // var createURL = that.APIENDPOINT + '/onboarding/appointments/signage/' + that.apiR.appointment_bookingcode + '/' + that.apiR.appointment_crypt + '/do'

      axios.post(createURL, {
       sign_in_base64: that.signature
      }).then(function () {
        EventBus.$emit('submit_step_app600')
      })
      .catch(function (error) {
        console.log('catch Function of AJAX-Called... error()');
        console.log(error);
      });
    });
  },
  beforeDestroy() {
      EventBus.$off('send_sketch_data');
      EventBus.$off('pre_pre_submit_step_app600');
      EventBus.$off('pre_submit_step_app600');
  },
  components: {
    'submit-button': SubmitButton,
    'sketchpad': Sketchpad
  },
    watch:{
      signature: function(newValue, oldValue){
          if(newValue != oldValue && newValue != ''){
              this.checkValid();
          }
      }
  },
  computed: {
    isOver18: function(){
      let bd = Number(this.aD.customer_appointment_birthday)
      var today = new Date();
      var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      if(((myToday.valueOf() - bd )/ 1000 + 1) > 568024668){  // Number for 18 years in seconds
        return true
      }else{
        return false
      }
    }
  },
  methods: {
    submit: function() {
        this.log('Appointment01.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    startOnBoarding(){
      EventBus.$emit("start_onboarding");
    },
    changeStep(step){
      EventBus.$emit('changeStep', {
            'step_number': step
          });
    },

    checkValid(){
      if(this.apiR.paymentType == 'Kostenfrei' || this.apiR.paymentType == 'Ermaessigt'){
          if (
            this.check1 == true &&
            this.check2 == true &&
            this.check3 == true &&
            this.check4 == true &&
            this.check5 == true &&
            this.check6 == true
            && this.signature == true
          ){
            this.isValid = true
          }else{
            this.isValid = false
          }
        }else{
          if (
            this.check2 == true &&
            this.check3 == true 
            &&  this.signature == true
          ){
            this.isValid = true
          }else{
            this.isValid = false
          }
        }
    },
    selectAll(){
        this.check2 = true;
        this.check3 = true;
         if(this.apiR.paymentType == 'Kostenfrei' || this.apiR.paymentType == 'Ermaessigt'){
          this.check1 = true;
          this.check4 = true;
          this.check5 = true;
          this.check6 = true;
        }
        this.checkValid();
      }
    
    
  }
}
</script>

<style scoped>
.line{
  height: 1px;
  /* background-color: black; */
}
.bg-lgray{
  background-color: #f4f4f4;
}
.inactive{
  pointer-events: none;
  background-color: #a3a3a3;
}

/* The container */
.dsgvo-container {
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.dsgvo-container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

/* Create a custom checkbox */
.dsgvo-check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border: 3px solid #06c;
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
/* .checkbox-container:hover input[type=radio] ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
/* .checkbox-container input[type=radio]:checked ~ .checkmark {
  background-color: #2196F3;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.dsgvo-check::before {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dsgvo-container input[type=checkbox]:checked ~ .dsgvo-check::before {
  display: block;
}

.dsgvo-tick{
  position: absolute; 
  top: 2px;
  left: 2px;
}
.dsgvo-tick svg{
  width:14px;
}

</style>