<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-col-7 lg-pl3 lg-pt1 mx-auto border-box px2 border-box">
              <h2 class="h1-5 bold color1 condensed pb3 pt2 lh-1">Termin bestätigt!</h2>
              <div class="pb3 pt2 lh-1" v-if="apiResponse.paymentNeeded">
                  <h2 class="h2 h2_backwards condensed pb2 lh-2">Produkt-Auswahl:
                    <span class="color1">{{apiResponse.paymentProduct}}</span>
                  </h2>
                  <h2 class="h2 h2_backwards condensed pb2 lh-2">Zahlungsweise:
                    <span class="color1">{{apiResponse.paymentType}}</span>
                  </h2>
                  <h2 class="h2 h2_backwards condensed pb2 lh-2">Preis:
                    <span v-if="apiResponse.paymentType == 'Kostenfrei'" class="color1">0,00 €</span>
                    <span v-else class="color1">{{apiResponse.paymentPrice}} €</span>
                  </h2>
                 
                  <br>
                  <p v-if="apiResponse.paymentType == 'Paypal'" class="p extra-light gray">{{texts.PAYMENT1_P}}</p>
                  <p v-if="apiResponse.paymentType == 'EC-Lastschrift (BAR)'" class="p extra-light gray">{{texts.PAYMENT2_P}}</p>
                  <p v-if="apiResponse.paymentType == 'Kostenfrei'" class="p extra-light gray">{{texts.PAYMENT3_P}}</p>
              </div>
              <p class="p extra-light gray">Bitte kommen Sie am 
                <span class="bold">{{onBoardingAppointmentDateDefault}}</span> um <span class="bold">{{onBoardingAppointmentTimeDefault}}</span> zum {{texts.TEXT_COMPLETED}}<br/><br/>
              </p>
              <div v-if="keep_old_process == 'false'" class="md-flex" style="gap: 1rem;">
              <img class="mb2" style="width: 2rem; height: 100%;" src="./assets/images/leaf-icon.svg"/>
              <p class="p extra-light gray mb3">
                Durch unsere digitale Arbeitsweise können Sie den Erfassungsbogen einfach <b class="bold">online weiter ausfüllen</b> und unterschreiben, sodass ein Ausdruck nicht mehr nötig ist. Helfen Sie uns, unnötigen Papiermüll zu vermeiden!
              </p>
              </div>

              <!-- Kein PayPal -->
              <div class="" v-if="keep_old_process == 'false' && apiResponse.paymentType != 'Paypal'">
                <submit-button name="Formular online weiter ausfüllen" :validInput="true" event_name="pre_submit_step_app500" :btnAnimation="false"/>
                <p class="p extra-light gray my3">Alternativ ist es immer noch möglich, den Erfassungsbogen ausgedruckt und händisch unterschrieben mitzubringen. Ohne den augefüllten Bogen kann kein Test durchgeführt werden.</p>
                <div>
                  <a v-bind:href="onBoardingAppointmentLinkTask" target="_blank" class="btn std center ghostbtn mb3 px3" style="color: darkred; font-size: 1rem;">Erfassungsbogen runterladen</a>
                </div>
              </div>

              <!-- PayPal als Zahlungsart -->
              <!-- <div class="" v-if="keep_old_process == 'false' && apiResponse.paymentType == 'Paypal'">
                <submit-button name="Formular online weiter ausfüllen" :validInput="true" event_name="pre_submit_step_app500" :btnAnimation="false"/>
                <p class="p extra-light gray my3">Wenn Sie den Erfassungsbogen analog unterschreiben möchten, können Sie jetzt per PayPal bezahlen und den Erfassungsbogen anschließend ausdrucken.</p>
                <div class="mb3">
                  <payment-button btnClass='ghostbtn' :link="apiResponse.paypalLink" :name="'Per PayPal zahlen und Erfassungsbogen runterladen'"></payment-button>
                </div>
              </div> -->

              <div v-if="keep_old_process == 'true'">
                <p class="p extra-light gray my3">Bitte bringen Sie den Erfassungsbogen <b>ausgedruckt</b> und <b>unterschrieben</b> mit.</p>
                <div>
                  <a v-bind:href="onBoardingAppointmentLinkTask" target="_blank" class="btn std center ghostbtn mb3 px3" style="color: darkred; font-size: 1rem;">Erfassungsbogen runterladen</a>
                </div>
                 <div v-if="apiResponse.paymentType == 'Paypal'">
                     <payment-button :href="apiResponse.paypalLink" :name="'Jetzt per PayPal zahlen'"></payment-button>
                  </div> 
              </div>
              <div class="">
              <p class="p extra-light gray mx2">
                {{texts.THANKYOU_TEXT}}
              </p>
              </div>
              <div class="grey-box mr2" v-if="onBoardingAppointmentImageSRC != ''">
                <img class="cwa-logo" src="../src/assets/images/cwa-logo.png" alt="">
                <br>
                  <p>
                  Um den Schnelltest in der Corona-Warn App zu öffnen führen Sie bitte folgentde Schritte durch:
                  <br>
                  Scannen Sie den QR-Code, um in die Corona Warn-App zu gelangen.
                  <br>
                  Erteilen Sie dort das Einverständnis zum abrufen des Testergebnisses.
                  <br>
                  Wenn Sie diese Schritte durchgeführt haben, können Sie das Ergebniss in die Corona-Warn App übernehmen und als Nachweis nutzen.
                  </p>
                  <br>
                <img :src="onBoardingAppointmentImageSRC" alt="">
              </div>

              <div class="mb4"></div>
            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import CTASupportElement from './CTASupportElement.vue'
import PaymentButton from './PaymentButton.vue'
import SubmitButton from './SubmitButton.vue'

export default {
  name: 'AppointmentStep05',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentDateDefault: String,
    onBoardingAppointmentTimeDefault: String,
    apiResponse:  {},
    onBoardingAppointmentImageSRC: String,
    onBoardingAppointmentTestcenter: Number

  },
  data() {
    return {
      notfilled: false,
      debug: false,
      dataEvents: {
        machinecategory_selected: false
      },
      style_class: '',

      isValid: false,
      
     onBoardingAppointmentLinkTask: '',
     
      texts: {
        TEXT_COMPLETED: process.env.VUE_APP_TEXT_COMPLETED,
        TESTZENTRUM: process.env.VUE_APP_TESTZENTRUM,
        THANKYOU_TEXT: process.env.VUE_APP_THANKYOU_TEXT,
        PAYMENT1_P: process.env.VUE_APP_PAYMENT1_P,
        PAYMENT2_P: process.env.VUE_APP_PAYMENT2_P,
        PAYMENT3_P: process.env.VUE_APP_PAYMENT3_P,
      },
      keep_old_process:  process.env.VUE_APP_KEEP_OLD_PROCESS
    }
  },
  created() {
    if(this.style != "") {
      this.style_class = this.style;
    }

    if(this.texts.THANKYOU_TEXT == undefined)
    {
      this.texts.THANKYOU_TEXT = 'Wir haben Ihnen zudem eine E-Mail mit der Bestätigung Ihres Termins geschickt.';
    }
    
    EventBus.$on('pre_submit_step_app500', () => {
      if(this.date != "") {
        this.notfilled = false;
          EventBus.$emit('submit_step_app500', {
           "appointment_time": this.time
          });
      } else {
        // TODO: Alertbox nothing inserted!
        this.notfilled = true;
      }
    });
    EventBus.$on('submit_step_downloadlink', (payload) => {
      this.onBoardingAppointmentLinkTask = payload.onBoardingAppointmentLinkTask
    });
  },
  components: {
    'cta-support-element': CTASupportElement,
    'payment-button' : PaymentButton,
    'submit-button': SubmitButton,
  },
   watch: {
    
     onBoardingAppointmentTestcenter: function(newValue, oldValue) {
       console.log('AppStep01.vue: new onBoardingAppointmentTestcenter found, new: ' + newValue + ' old: ' + oldValue);

         if(newValue == 1) {
            this.texts.TEXT_COMPLETED = process.env.VUE_APP_TEXT_COMPLETED;
             
          }

         if(newValue == 2) {
          this.texts.TEXT_COMPLETED = process.env.VUE_APP_SECONED_TEXT_COMPLETED;
          
         }
         if(newValue == 3) {
          this.texts.TEXT_COMPLETED = process.env.VUE_APP_THIRD_TEXT_COMPLETED;
          
         }
         if(newValue == 4) {
          this.texts.TEXT_COMPLETED = process.env.VUE_APP_FOURTH_TEXT_COMPLETED;
          
         }
     }
  },
  methods: {
    submit: function() {
        this.log('Appointment01.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    startOnBoarding(){
      EventBus.$emit("start_onboarding");
    }
    
    
  }
}
</script>

<style scoped>
.underlined{
  border-bottom: 1px solid #06c;
  cursor:pointer;
}
.h2_backwards {
color: #9395a6;
font-size: 20px;
}
div.grey-box {
  margin-top: 2rem;
	height: 120px;
	display: flex;
	justify-content: space-between;
	background-color: #ccc;
	border: 2px solid #000;
	padding: 1rem;
  margin-left: 1rem;
}
div.grey-box p {
	font-size: 12px;
	padding: .5rem;
	padding-top: 0;
	margin-top: -4px;
}
div.grey-box img {
  height: 100%;
}
@media(max-width: 1440px){
  div.grey-box {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-left: 0;
  }
  div.grey-box img {
    height: 250px;
    width: 250px;
  }
  div.grey-box img.cwa-logo {
    height: 150px;
    width: 130px;
  }
  div.grey-box p {
    font-size: 12px;
    padding: .5rem;
    padding-top: .5rem;
    margin-top: 0;
  }
}
@media(max-width: 769px){
  .btn{
    margin-left: 0;
  }
  p{
    margin-left: 0;
    padding-left: 0;
  }
  h2{
    margin-left: 0;
    padding-left: 0;
  }
}
</style>