<template>
<div>
    <button class="btn std white mb2" :class="{inactive: !canvasTouched}" style=" padding: .5rem 1rem" @click="clearArea()">Signatur zurücksetzen</button>
    <div id="sketch" class="mb2" :style="{height: canvasHeight + 'px', width: 100 + '%', border: '1px solid black' }">
        <div class="signature-line"></div>
        <canvas id="can"></canvas>
    </div>
</div>
</template>

<script>

import { EventBus } from "@/event-bus.js";

export default {
  name: 'Sketchpad',
  props: {
      canvasHeight: Number
  },
  data() {
    return {
        canvasTouched: false,
    }
  },
  created() {
    EventBus.$on('request_sketch', () => {
        console.log('SketchPanel received request_sketch. Send sketch data.')
        EventBus.$emit('send_sketch_data', {
            'sketch_data': document.getElementById('can').toDataURL()
        })
    })
  },
  mounted() {
    let that = this;
    var canvas = document.querySelector('#can');
	var context = canvas.getContext('2d');
	
	var sketch = document.querySelector('#sketch');
	var sketch_style = getComputedStyle(sketch);
    canvas.width = sketch.clientWidth;
    canvas.height = parseInt(sketch_style.getPropertyValue('height'));


// create a drawer which tracks touch movements
var drawer = {
    isDrawing: false,
    touchstart: function (coors) {
        context.beginPath();
        context.moveTo(coors.x, coors.y);
        this.isDrawing = true;
        that.canvasTouched = true;
    },
    touchmove: function (coors) {
        if (this.isDrawing) {
            context.lineTo(coors.x, coors.y);
            context.stroke();
        }
    },
    touchend: function (coors) {
        if (this.isDrawing) {
            this.touchmove(coors);
            this.isDrawing = false;
        }
    }
};
    // create a function to pass touch events and coordinates to drawer
    function draw(event) { 
        event.preventDefault();
    var type = null;
    // map mouse events to touch events
    switch(event.type){
        case "mousedown":
                event.touches = [];
                event.touches[0] = { 
                    pageX: event.pageX,
                    pageY: event.pageY
                };
                type = "touchstart";                  
        break;
        case "mousemove":                
                event.touches = [];
                event.touches[0] = { 
                    pageX: event.pageX,
                    pageY: event.pageY
                };
                type = "touchmove";                
        break;
        case "mouseup":                
                event.touches = [];
                event.touches[0] = { 
                    pageX: event.pageX,
                    pageY: event.pageY,
                };
                type = "touchend";
        break;
    }        

    // touchend clear the touches[0], so we need to use changedTouches[0]
    var coors;
    if(event.type === "touchend") {
        coors = {
            x: event.changedTouches[0].pageX - this.offsetLeft,
            y: event.changedTouches[0].pageY - this.offsetTop
        };
    }
    else {
        // get the touch coordinates
        coors = {
            x: event.touches[0].pageX - this.offsetLeft,
            y: event.touches[0].pageY - this.offsetTop
        };
    }
    type = type || event.type
    // pass the coordinates to the appropriate handler
    drawer[type](coors);
}

// detect touch capabilities
var touchAvailable = ('createTouch' in document) || ('ontouchstart' in window);

// attach the touchstart, touchmove, touchend event listeners.
if(touchAvailable){
    canvas.addEventListener('touchstart', draw, false);
    canvas.addEventListener('touchmove', draw, false);
    canvas.addEventListener('touchend', draw, false);        
}    
// attach the mousedown, mousemove, mouseup event listeners.
else {
    canvas.addEventListener('mousedown', draw, false);
    canvas.addEventListener('mousemove', draw, false);
    canvas.addEventListener('mouseup', draw, false);
}

  },
    beforeDestroy() {
      //do something before destroying vue instance
        EventBus.$off('request_sketch');
    },
    watch:{
        canvasTouched: function(newValue, oldValue){
            if(newValue != oldValue){
                console.log('broadcast sketchState!')
                console.log(newValue)
                    EventBus.$emit('broadcast_sketchState', {
                        'sketchState': newValue 
                    });
            }
        }
  },
  methods: {
      clearArea() {
        // Use the identity matrix while clearing the canvas
        var canvas = document.querySelector('#can');
        var ctx = canvas.getContext('2d');
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);    
        this.isValid = false;
        this.canvasTouched = false;
    },
  },
}
</script>

<style scoped>
    .inactive{
    pointer-events: none;
    background-color: #a3a3a3;
    }
    .signature-line{
        position: relative;
        top: 50%;
        left: 0;
        width: 100%;
        border-bottom: 1px dashed #808080;
        pointer-events: none;
    }
</style>