<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-pt1 mx-auto px2 border-box mb3">
              <h1 class="my2">Digitales Formular erfolgreich ausgefüllt!</h1>
              <h3 class="mb3">{{texts.VUE_APP_FORM_CONTINUATION_SUCCESS}}</h3>
              <div v-if="apiResponse.paymentType == 'Paypal'">
                    <payment-button btnClass='' :link="apiResponse.paypalLink" :name="'Jetzt per PayPal zahlen'"></payment-button>
               </div> 
              <div class="mt3">
                <a class="btn std white center" href="/">Zurück zum Start</a>
              </div>
            </div>
    </div>
</template>

<script>

import PaymentButton from './PaymentButton.vue'
export default {
  name: 'AppointmentStep07',
  props: {
        apiResponse:  {},
  },
  data() {
    return {
      texts: {
        VUE_APP_FORM_CONTINUATION_SUCCESS: process.env.VUE_APP_FORM_CONTINUATION_SUCCESS,
      },
     
    }
  },
  components: {
    'payment-button' : PaymentButton
  },
}
</script>

<style scoped>
</style>