<template>
      <div class="white step-holder mx-auto pricing-info" v-show='pricingInfo.show_info_box'>
        <div class="lg-px2 px1 bg-darkred py2" style="margin-bottom: .25rem">
          <h1 class="h1-5  condensed medium pb1 lh-2">Herzlich Willkommen beim Covid-Testcenter des DRK Kreisverband Delmenhorst e.V.</h1>
          <p class="p-medium mb2 extra-light pt2 lh-2 ls-1">Für die Durchführung eines Covid-Tests in unserem Testcenter erheben wir einen Kostenbeitrag von 3 Euro pro Test, den Sie per EC-Karte als auch Bar ausgleichen können. Kostenfreie Tests können nicht mehr angeboten werden. 
          <p class="p-medium mb2 extra-light pt2 lh-2 ls-1 underline pointer" @click="showAdditionalPricingInfo = !showAdditionalPricingInfo">{{showAdditionalPricingInfo ? 'Zusatzinfos verbergen' : 'Zusatzinfos anzeigen'}}</p>
        </div>
        <div v-show="showAdditionalPricingInfo">
        <pricing-info-el 
          :heading="pricingInfo.box_03_heading"
          :text="pricingInfo.box_03_text"
          :excerpt="pricingInfo.box_03_excerpt"
        />
       <pricing-info-el 
          :heading="pricingInfo.box_01_heading"
          :text="pricingInfo.box_01_text"
          :excerpt="pricingInfo.box_01_excerpt"
        />
        </div>
      </div>
</template>

<script>

import PricingInfoElNew from './PricingInfoElNew.vue'

export default {
  name:'PricingInfoNew',
  data(){
    return {
      showBoxContent: false,
      showAdditionalPricingInfo: true,
      pricingInfo: {
      show_info_box: process.env.VUE_APP_SHOW_PRICING_INFO,
      box_01_heading: process.env.VUE_APP_PRICING_INFO_FREE_HEADING,
      box_02_heading: process.env.VUE_APP_PRICING_INFO_3EURO_HEADING,
      box_03_heading: process.env.VUE_APP_PRICING_INFO_10EURO_HEADING,
      box_01_text: process.env.VUE_APP_PRICING_INFO_FREE_PRICING_TEXT,
      box_02_text: process.env.VUE_APP_PRICING_INFO_3EURO_PRICING_TEXT,
      box_03_text: process.env.VUE_APP_PRICING_INFO_10EURO_PRICING_TEXT,
      box_01_excerpt: process.env.VUE_APP_PRICING_INFO_FREE_PRICING_EXCERPT,
      box_02_excerpt: process.env.VUE_APP_PRICING_INFO_3EURO_PRICING_EXCERPT,
      box_03_excerpt: process.env.VUE_APP_PRICING_INFO_10EURO_PRICING_EXCERPT,
    }
    }
  },
  components: {
    'pricing-info-el': PricingInfoElNew
  }
}


</script>

<style scoped>
.bg-darkred{
  background-color: darkred;
}
</style>